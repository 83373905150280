<template>
  <div>
    <v-card class="pa-4" max-width="320" min-height="300">
      <v-card-title class="panel-title">Edit Subscription</v-card-title>
      <v-card-text v-if="subscriptionModel">
        <v-form ref="subscriptionForm" v-model="valid" @submit.prevent="submit">
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Email"
                v-model="subscriptionModel.email"
                :rules="[rules.required]"
                :disabled="loading"
                validate-on-blur
                outlined
              ></v-text-field>

              <v-text-field
                label="Course ID"
                v-model="subscriptionModel.courseId"
                :rules="[rules.required]"
                :disabled="loading"
                validate-on-blur
                outlined
              ></v-text-field>
              <v-text-field
                label="User ID"
                v-model="subscriptionModel.userId"
                :rules="[rules.required]"
                :disabled="loading"
                validate-on-blur
                outlined
              ></v-text-field>
              <v-text-field
                label="Start Date"
                v-model="subscriptionModel.started"
                :rules="[rules.required]"
                :disabled="loading"
                validate-on-blur
                outlined
                type="datetime-local"
              ></v-text-field>
              <v-text-field
                label="End Date"
                v-model="subscriptionModel.end"
                :rules="[rules.required]"
                :disabled="loading"
                validate-on-blur
                outlined
                type="datetime-local"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row dense class="align-center justify-space-between">
            <v-col cols="12" xs="12" sm="4">
              <v-btn :loading="loading" large color="primary" @click="submit" block>
                Save
              </v-btn>
            </v-col>
            <v-col cols="12" xs="12" sm="4">
              <v-btn @click="$emit('close-dialog')" large outlined block>
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    subscriptionModel: {
      type: Object,
      default: () => ({}), // Ensure you have a fallback object
    },
  },
  computed: {
    ...mapGetters([
      "formatDateMed", // Assuming you're using this for date formatting. Include other getters as needed.
    ]),
  },
  methods: {
    ...mapActions("api", ["httpPost"]),
    ...mapActions(["showMessage"]),
    validate() {
      this.$refs.subscriptionForm.validate(); // Validate form inputs
    },
  async submit() {
  this.validate();
  if (!this.valid) {
    this.showMessage({ text: "Please correct the errors.", error: true });
    return;
  }
  console.log("Submitting:", this.subscriptionModel); // Add this line to debug
  this.loading = true;
      // Determine the URL based on the presence of an ID (edit or create)
      const actionUrl = this.subscriptionModel._id ? "/admin/update_subscription" : "/admin/create_free_subscription";
      try {
        await this.httpPost({
          withAuth: true,
          url: actionUrl,
          data: this.subscriptionModel,
        });
        const successMessage = this.subscriptionModel._id ? "Subscription updated successfully." : "Subscription created successfully.";
        this.showMessage({ text: successMessage, error: false });
        this.$emit("refresh"); // Notify parent to refresh the list
      } catch (error) {
        this.showMessage({ text: error.message || "An error occurred.", error: true });
      } finally {
        this.loading = false;
      }
    },
  },
  data() {
    return {
      valid: false,
      loading: false,
      rules: {
        required: value => !!value || 'Required.',
        // Add more rules as necessary
      },
    };
  },
};
</script>

<style scoped>
.panel-title {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 20px;
}
</style>
