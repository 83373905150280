<template>
  <v-container>
    <v-card class="pa-4" shaped>
      <v-row class="justify-center">
        <v-col>
          <h2 class="ml-6">Subscriptions</h2>
        </v-col>
      </v-row>
      <v-card-text>
        <v-data-table
          :headers="header"
          :items="subscriptions"
          :loading="loading"
          class="elevation-0"
          :server-items-length="count"
        >
          <template v-slot:item.createdAt="{ item }">
            <div class="txt">{{ formatDateMed(item.createdAt) }}</div>
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon class="mr-16" color="blue" @click="editSubscription(item)">mdi-pencil</v-icon>
          </template>
        </v-data-table>
      </v-card-text>
      <v-fab-transition>
        <v-btn @click="addSubscription" color="pink" :disabled="loading" fab dark small absolute bottom right>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-card>
    <v-dialog v-model="dialog" max-width="620px">
      <SubscriptionEdit :subscription="selectedItem" @close-dialog="closeDialog" @refresh="fetchSubscriptions" />
    </v-dialog>
  </v-container>
</template>

<script>
import SubscriptionEdit from "./subscriptionEdit.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    SubscriptionEdit,
  },
  data() {
    return {
      dialog: false,
      warnDlg: false,
      loading: false,
      selectedItem: null,
      subscriptions: [],
      count: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['started'],
        sortDesc: [true],
      },
      header: [
        { text: 'User Email', value: 'email' },
         { text: 'UserID ID', value: 'userId' },
        { text: 'Course ID', value: 'courseId' },
        { text: 'Start Date', value: 'started' },
        { text: 'End Date', value: 'end' },
        { text: 'Actions', value: 'action', sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters([
      // Include any getters you need from the store
    ]),
  },
  methods: {
    ...mapActions('api', ['httpGet', 'httpPost']),

    async getOptions(options) {
      this.options = options;
      this.loading = true;
      try {
        const response = await this.httpGet({
          withAuth: true,
          url: "/admin/get_all_subscriptions", // Endpoint URL
          data: {
            limit: this.options.itemsPerPage,
            page: this.options.page,
            sortBy: this.options.sortBy.join(','),
            sortDesc: this.options.sortDesc.join(','),
            // include other options as necessary
          },
        });
        this.subscriptions = response.data; // assuming the response is the direct array of subscriptions
        this.count = response.total; // assuming there's a total count in the response
      } catch (error) {
        console.error('Failed to fetch subscriptions:', error);
        // Handle error appropriately
      } finally {
        this.loading = false;
      }
    },

    addSubscription() {
      this.dialog = true;
      this.selectedItem = {}; // Reset for new entry
    },

    editSubscription(item) {
      this.dialog = true;
      this.selectedItem = item;
    },

    openWarn(item) {
      this.warnDlg = true;
      this.selectedItem = item;
    },

    async removeSubscription() {
      this.loading = true;
      try {
        await this.httpPost({
          withAuth: true,
          url: `/admin/remove_subscription/${this.selectedItem.id}`, // Modify as necessary
          data: {}, // If additional data is needed
        });
        // Assuming deletion is successful
        this.getOptions(this.options); // Refresh list
        this.warnDlg = false; // Close warning dialog
      } catch (error) {
        console.error('Failed to remove subscription:', error);
        // Handle error appropriately
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.getOptions(this.options); // Initial fetch
  },
  watch: {
    options: {
      handler: 'getOptions',
      deep: true,

    },
  },
};
</script>


<style scoped>
.txt {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
</style>
